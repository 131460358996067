<template>
  <b-card class="shadow-sm rounded login-body mb-5">

    <div v-if="invalidLink" class="alert alert-custom alert-notice alert-light-danger fade show" role="alert">
        <div class="alert-icon"><i class="flaticon-warning"></i></div>
        <div class="alert-text">{{$t('ACCOUNT.INVALID_LINK')}}</div>
    </div>

    <b-card class="forget-body mb-5" v-if="showChangePassword && !invalidLink">
      <h3 class="text-center mt-4 mb-4">{{$t('ACCOUNT.UPDATE_PASSWORD')}}</h3>


      <b-form class="login-form" v-on:submit.prevent="onSubmit">

        <b-alert
          :show="showWarning"
          variant="danger"
          dismissible
          @dismissed="showWarning = false"
          fade
          >{{ warningText }}</b-alert
        >

        <b-form-input type="password" v-model="password1" class="mb-8" :placeholder="$t('MEMBER.PASSWORD')" />
        <b-form-input type="password" @keyup.enter="onSubmit" v-model="password2" class="mb-8" :placeholder="$t('MEMBER.REPEAT_PASSWORD')" />

        <RightSaveButton
          ref="save-button"
          :text="$t('COMMON.SAVE')"
          @clicked="onSubmit()"
        />
      
      </b-form>

      
    </b-card>


    <b-card class="forget-body mb-5" v-if="!showChangePassword">
      <h3 class="text-center mt-4 mb-4">{{$t('COMMON.DONE')}}</h3>

      <div class="text-center">{{$t('ACCOUNT.PASSWORD_WAS_CHANGED')}}</div>

      <div class="text-center mt-12">
          <router-link :to='loginLink()' tag="a" class="line-link blue-link"
            >{{$t('ACCOUNT.TO_LOGIN')}}
          </router-link>
        </div>
    </b-card>
  </b-card>
</template>

<style lang="scss" scoped>

.login-body {
  max-width: 500px;
  width: 100%;
  .login-form {
    padding: 2rem 1.5rem 2rem 1.5rem;
    .btn-login {
      font-size: 1.2rem;
      font-weight: 500;
      padding: 1rem 3rem;
      background-color: #5d78ff;
      border-color: #5d78ff;
      &:hover {
        background-color: #3758ff;
        border-color: #2a4eff;
      }
    }
  }
  .warning-message {
    padding: 30px 20px;
  }
}


.forget-body {
  
  max-width: 500px;
  width: 100%;


  .forget-form {
    padding: 2rem 1.5rem 2rem 1.5rem;
    .btn-login {
      font-size: 1.2rem;
      font-weight: 500;
      padding: 1rem 3rem;
      background-color: #5d78ff;
      border-color: #5d78ff;
      &:hover {
        background-color: #3758ff;
        border-color: #2a4eff;
      }
    }
  }
}
</style>

<script>
import messageParent from '@/core/services/messageParent';
import { mapState } from 'vuex';
import axios from 'axios';
import { validationMixin } from 'vuelidate';
import { email, required } from 'vuelidate/lib/validators';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import RightSaveButton from '@/view/components/buttons/RightSaveButton.vue';
import { MEMBER_LOGIN, MEMBER_LOGOUT, MEMBER_SET_COMPANY, MEMBER_SET_LOGINLINK } from '@/core/services/store/member.auth.module';
import { setKeyValue, getKeyValue } from '@/core/services/simpleStore';

export default {
  mixins: [ validationMixin ],
  name: 'ResetPasswordMember',
  mixins: [ toasts ],
  components: {
    RightSaveButton
  },
  data() {
    return {
      // Remove this dummy login info
      invalidLink: false,
      loading: true,
      password1: '',
      password2: '',
      token: null,
      showChangePassword: true,
      form: {
        email: '',
        company_id:''
      },
      showWarning: false,
      warningText: ''
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      }
    }
  },
  methods: {
    async onSubmit() {
      this.savePassword();
    },

    savePassword() {
      if (this.password1 !== this.password2) {
        this.showWarning = true;
        this.warningText = this.$t('ACCOUNT.PASSWORDS_MISMATCH');
        this.$refs['save-button'].stop();
        return;
      }

      if (this.password1.length < 6) {
        this.showWarning = true;
        this.warningText = this.$t('ACCOUNT.PASSWORDS_6CHAR');
        this.$refs['save-button'].stop();
        return;
      }
    
      this.showWarning = false;

      this.changePassword(this.password1);
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async changePassword(newpassword) {
      try {
        const res = await axios.post('/member/reset', { email: this.form.email, password: newpassword, token: this.token });

        if (res.status === 200) {
          this.showChangePassword = false;
          this.$refs['save-button'].stop();

          setKeyValue('member_login_email', res.data.member.email);
          this.$store.dispatch(MEMBER_SET_COMPANY, { company_id: res.data.company_id });
          this.$store.dispatch(MEMBER_LOGIN, res.data);

          this.$router.push({ name: 'profile' });

          return;
        }

      }
      catch (err) {
        console.error('change password error', err);

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ACCOUNT.UNABLE_RESET'))
      }

      this.$refs['save-button'].stop();
      
    },
    resetForm() {
      this.form = {
        email: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    loginLink() {
      return '/login/' + this.form.company_id;
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  },
  mounted(){
    const loader = this.$loading.show();
    const token = this.$route.params.token;
    this.token = token;

    axios 
      .get(`/member/resettoken?token=${token}`)
      .then(res => {
        if (res.status === 200) {
          this.invalidLink = false;
          this.form = res.data;
        }
        else {
          this.invalidLink = true;
        }
        
        loader & loader.hide();
      })
      .catch(err => {
        this.invalidLink = true;
        this.showWarning = true;
        this.warningText = this.$t('MEMBER.INVALID_RESET_LINK');
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), 'Felaktig länk')
        loader & loader.hide();
      });
      
    setTimeout(function(){
      messageParent(JSON.stringify({ height: document.body.scrollHeight }));
    }, 300);
  }
};
</script>
